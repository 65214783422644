import React from "react";
import PropTypes from "prop-types";
import Icon from "theme/components/atoms/Icon";
import Body from "theme/components/atoms/Typography/Body";
import classnames from "classnames";
import deprecateRenamedProperty from "web/core/dx/deprecateRenamedProperty";

const Notification = ({ children }) => (
  <span className="labelled-icon__icon__notification">{children}</span>
);

const LabelledIcon = ({
  icon,
  children,
  inverted,
  appearance,
  type,
  size,
  notification,
}) => {
  if (!appearance) {
    appearance = deprecateRenamedProperty(
      "LabelledIcon",
      "type",
      "appearance",
      type
    );
  }

  const classes = classnames("labelled-icon", {
    [`labelled-icon--${appearance}`]: appearance,
  });
  return (
    <div className={classes}>
      <div className="labelled-icon__content">
        {inverted && <Body>{children}</Body>}
        <span className="labelled-icon__icon">
          {notification && notification > 0 ? (
            <Notification>{notification}</Notification>
          ) : null}
          <Icon title="" icon={icon} size={size} />
        </span>
        {!inverted && <Body>{children}</Body>}
      </div>
    </div>
  );
};

LabelledIcon.propTypes = {
  icon: Icon.propTypes.icon,
  children: Body.propTypes.children,
  inverted: PropTypes.bool,
  type: PropTypes.oneOf(["default", "vertical"]),
  appearance: PropTypes.oneOf(["default", "vertical"]),
  size: Icon.propTypes.size,
  notification: PropTypes.number,
};

LabelledIcon.defaultProps = {
  size: "small",
};

export default LabelledIcon;
